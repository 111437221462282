.quick-exit {
  background-color: #d24747!important;
  font-weight: 500;
  position: fixed;
  bottom: 24px;
  right: 16px;
  border-radius: 50%!important;
  padding: 15px!important;
  font-size: 0.85rem;
  width: 54px;
  height: 54px;
  text-align: center;
  vertical-align: middle;
  line-height: 27px;
  z-index: 100!important;
}

.vietnamese {
  padding: 17px 8px!important;
  font-size: 0.8rem;
}

.quick-exit span {
  display: none;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {  }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  .quick-exit {
    font-size: 1rem;
    border-radius: 2rem!important;
    padding: 1rem 2rem!important;
    /* position: relative;
    top: 0;
    right: 0; */
    right: 1.5rem;
    bottom: 2rem;
    box-shadow: none!important;
    /* width: auto; */
    width: 170px;
    height: auto;
    text-align: inherit;
    /* vertical-align: bottom; */
    line-height: inherit;
  }

  .quick-exit:hover {
    background-color: #B82727!important;
  }

  .quick-exit span {
    display: inline;
  }

}


/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) {
  .quick-exit {
    position: relative;
    top: 0;
    right: 0;
    box-shadow: none!important;
  }
}

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
