nav {
    z-index: 500;
}

nav.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.dropdown-item {
  background-color: #ECF0F8;
  border: 10px solid #ECF0F8;
  border-radius: 8px;
}

.dropdown-item-smaller {
  background-color: #ECF0F8!important;
  border: 12px solid #ECF0F8!important;
  border-radius: 8px!important;
}

.prev-question-on-mobile {
  background-color: #ECF0F8!important;
  border: 8px solid #ECF0F8!important;
  border-radius: 9px!important;
}

.current-language {
  /* text-shadow:0 0 3px #FEFECA, 0 0 7px #FEFECA, 0 0 16px #FEFECA; */
  /* text-shadow: 3px 3px 20px #EDD154, -2px 1px 30px #EDD154!important; */
  font-weight: bold;
}

#back-button svg {
  vertical-align: text-top;
}

#mobile-menu {
  min-height: calc(100vh - 4rem);
  min-height: calc(100dvh - 4rem);
  height: 100%;
  overflow: auto;
}

.mobile-menu-item-border-bottom {
  width: calc(100% + 1rem);
  text-align: left;
  /* margin-left: -0.5rem;
  margin-right: -0.5rem; */
  padding-left: 1.5rem!important;
  border-bottom: 1px solid #072149;
  margin: 0 -0.5rem!important;
}
/*
hr.mobile-menu-item-border-bottom {
  margin-top: 2rem!important;
  height: 1px;
} */

.mobile-menu-separator {
  margin: 2rem -0.5rem 0 -0.5rem!important;
  height: 1px;
  width: 100vh;
  background-color: #ffffff;
}

#mobile-menu > div > a > svg {
  float: left;
  margin-bottom: 0.1rem;
}

.stroke-width-1-15 {
  stroke-width: 1.15!important;
}

.stroke-width-1-25 {
  stroke-width: 1.25!important;
}

.stroke-width-1-75 {
  stroke-width: 1.75!important;
}

.stroke-width-2 path {
   stroke-width: 2!important;;
}

.stroke-width-2-25 path {
   stroke-width: 2.25!important;;
}

.stroke-width-3 path {
   stroke-width: 3!important;;
}

#headlessui-portal-root {
  position: absolute;
  z-index: 900;
}

.mobile-dropdown-panel {
  margin-left: -8px;
  margin-right: -8px;
  width: 100vw!important;
}

.logo-container, .logo-full {
  height: 6rem!important;
  padding: 1.5rem 0.15rem;
}

#about-app svg, #privacy-txt svg, #website-terms svg {
  padding: 0.09rem!important;
  position: relative;
  left: 1px;
}

.menu-items-separator {
  border-top: 1px solid #072149;
}

.mobile-menu-item-correction {
  padding-left: 1.25rem!important;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  /* .logo-container, .logo-full {
    height: 5rem!important;
    padding: 1.85rem 0;
  } */

  /* Contact button */
  nav > div > div > div:nth-child(2) > div > div:nth-child(2) > div > a.bg-brandSecondary {
    /* background-color: orange; */
    display: none;
  }

}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
  .logo-container, .logo-full {
    height: 6rem!important;
    padding: 1.35rem 0;
  }

  /* Contact button */
  nav > div > div > div:nth-child(2) > div > div:nth-child(2) > div > a.bg-brandSecondary {
    /* background-color: orange; */
    display: block;
  }
 }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
