.main-menu-h2 {
  /* font-size: 1.25rem; */
  font-size: 1.5rem;
  line-height: 2rem;
  color: #212529;
}

div.main-menu-panel > div > div {
  margin: 1rem auto;
}

div.main-menu-panel > div > div > button {
  /* text-align: left; */
  height: calc(100% - 24px);
  vertical-align: top;
  display: inline-block;
  margin: auto;
  font-weight: 500;
}

.card-done button {
  background-color: #00882A!important;
  /* background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F><svg viewBox="0 0 24 24" stroke-width="1.5" stroke="%233aaf4e" version="1.1" id="warning" width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" id="path1" style="fill:%23EDFFF0;fill-opacity:1;stroke-width:1.5"/></svg>');
  background-repeat: no-repeat; */
}

.card-done > button > div > svg {
    display: inline;
    margin-left: 0.5rem;
    width: 1.15rem;
    height: 1.15rem;
    vertical-align: text-top;
}
