#interview {
  min-height: calc(100vh - 104px)!important;
  min-height: calc(100dvh - 104px)!important;
}

.interview-body  {
  line-height: 1.75;
}

#back-button {
  font-weight: 500;
}

.g-map {
    width: calc(100vw - 2rem);
    height: 40vh;
    background-color: #CFCFCF;
}

.pulse-wrapper {
    display: inline;
    width: 32px;
    position: relative;
    left: -32px;
    margin-right: 1px;
}

.pulse-empty {
  margin-right: 11px;
  display: inline-block;
}

.buttonWithOutline {
  padding: 14px;
  border: 2px solid #BFE9F0;
}

.buttonWithOutline:hover {
  padding: 14px;
  border: 2px solid #90CCD6;
}

#interview-outer-container > div > button {
  min-width: 120px;
}

/* Taken from https://codepen.io/nzbin/pen/GGrXbp
 *
 * ==============================================
 * Dot Pulse
 * ==============================================
*/
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  color: #ffffff;
  box-shadow: 9999px 0 0 -3px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -3px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  color: #ffffff;
}

.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  color: #ffffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -3px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -3px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

.transition-to-gray {
    animation: to-gray 2.5s;
}

@keyframes to-gray {
    0% {color:#ffffff;}
    100%{color:#94a3b8;}
}

/* ==============================================
 * Accordion
 * ==============================================
*/

.accordion {
  margin: 0 -16px;
  padding: 0 16px;
  background-color: white;
}

.accordion-item-head {
  display: flex;
  justify-content: space-between;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  font-weight: 500;
  vertical-align: middle;
  line-height: 36px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.accordion-item-head:hover {
  background-color: #e2e8f0;
}

.accordion-item-head > div:first-child {
  line-height: 1.5rem;
  margin: 1rem 0.65rem 1rem 0;
}

.accordion-item-head > div:nth-child(2) {
  color: #ffffff!important;
  max-height: 40px;
  background-color: #072149!important;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin: auto 0 auto 0.65rem;
}

.accordion-item-head > div:nth-child(2):hover {
  color: #ffffff!important;
  background-color: #0E3776!important;
}

.accordion-item-head > div > svg {
  width: 24px;
  height: 24px;
}

div.accordion-item:first-child > div.accordion-item-head {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-item-head-last {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.item-content-toggle {
  cursor: default;
}

.accordion hr {
  border-color: #e2e8f0;
  opacity: 1;
}

/* ==============================================
 * Contact details
 * ==============================================
*/
.contact-details tr td {
  padding: 0.375rem 0;
}

.contact-details tr td:nth-child(2) {
  padding: 0.375rem 0 0.375rem 0.375rem;
}

.contact-details tr:first-child td {
  padding-top: 0;
}

.contact-details tr:last-child td {
  padding-bottom: 0;
}

.lth-vis-on-sm {
  display: none;
}

.lth-vis-on-xs-only {
  display: block;
}

.secondary, .secondary-wide {
  background-color: white!important;
  border: 2px solid #00a2c0 ;
  color: #00a2c0 !important;
  font-weight: 700!important;
}

.secondary {
  padding: 14px 16px!important;
}

.secondary-wide {
  padding: 10px 32px!important;
}

.secondary:hover {
  background-color: #E6E6E6!important;
  border-color: #007D94;
  color: #007D94!important;
}

.warning {
  background-color: #05805F!important;
}

.warning:hover {
  background-color:#035F4A!important;
}


.info {
  background-color: #00a2c0!important;
}

.info:hover {
  background-color:#007D94!important;
}

.danger {
  background-color: #C0F0EB!important;
  color: #000!important;
  border: 2px solid #C0E9F0;
}

.danger:hover {
  background-color: #90D6CF!important;
  color: #000!important;
  border: 2px solid #90CCD6;
}

.success {
  background-color: #8f61b3!important;
  color: #ffffff!important;
}

.success:hover {
  color: #ffffff!important;
  background-color: #8f61b3!important;
}

.warning-message-header {
  display: inline;
  /* vertical-align: middle; */
  position: relative;
  top: 6px;
  margin-right: 4px;
}

.warning-message-header::before {
  content: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F><svg viewBox="0 0 24 24" stroke-width="1" stroke="%238356A2" version="1.1" id="warning" width="24" height="23.99999" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" id="path1" style="fill:%23f7ecff;fill-opacity:1;stroke-width:1.5"/></svg>');
  width: 1rem;
  height: 1rem;
}

.warning-message {
  background-color: #f7ecff;
  border: 1px solid #D8A5FD;
  padding: 0.75rem 1rem 1rem 1rem;
  border-radius: 8px;
  color: black;
  box-shadow: 4px 4px 16px #E3C9F6;
  margin-bottom: 0.75rem;
}

.warning-message  a.termToDefine {
  border-bottom: 3px dotted #2D0749;
}


.info-message {
  background-color: #EDFFF0;
  border: 1px solid #9CF0AA;
  padding: 0.75rem 1rem 1rem 1rem;
  border-radius: 8px;
  color: black;
  box-shadow: 4px 4px 8px #C9F5D0;
  margin-bottom: 0.75rem;
}

.info-message a.termToDefine {
  border-bottom: 3px dotted #3AAF4E;
}

.info-message-header {
  display: inline;
  /* vertical-align: middle; */
  position: relative;
  top: 6px;
  margin-right: 2px;
}


.info-message-header::before {
  content: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F><svg viewBox="0 0 24 24" stroke-width="1.5" stroke="%233aaf4e" version="1.1" id="warning" width="24" height="23.99999" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" id="path1" style="fill:%23EDFFF0;fill-opacity:1;stroke-width:1.5"/></svg>');
  width: 1rem;
  height: 1rem;
}

.aspect-da-image {
  /* aspect-ratio: 16 / 9; */
  margin-top: 1rem;
}

.aspect-da-image img {
  position: relative;
  object-fit: cover;
  object-position: 50% 50%;
  aspect-ratio: 16 / 9;
  border-radius: 0.375rem;
  overflow: hidden;
  width: 100%;
}

.unordered-list {
  margin-top: 0.75rem;
  margin-left: 2rem;
  padding: 0;
  list-style: square;
}

.unordered-list li {
  color: #00a2c0 ;
  font-weight: 700;
  line-height: 1.75rem;
}

.unordered-list li span {
  color: #000;
  font-weight: 400;
}

.numbered-list {
  margin-top: 0.75rem;
  margin-left: 2rem;
  padding: 0;
  list-style: decimal;
}

.numbered-list li {
  color: #334155;
  font-weight: 700;
  line-height: 1.75rem;
}

.numbered-list li span {
  color: rgb(30, 41, 59);
  font-weight: 400;
}

#interview-outer-container > h1,
.modal-header {
  line-height: 1.85rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #212529!important;
}

/*
#interview-outer-container a.external-link,
#interview-outer-container a.phone-link,
#interview-outer-container a.email-link,
#interview-outer-container a.web-link,
#interview-outer-container a.form-link,
#interview-outer-container a.generic-link,
#generic-dialog a.external-link,
#generic-dialog a.phone-link,
#generic-dialog a.email-link,
#generic-dialog a.web-link,
#generic-dialog a.form-link {
  text-decoration: underline;
  color: #00a2c0 ;
} */

a.plain-link {
  text-decoration: underline;
  color: #00a2c0 ;
}

a.external-link-white {
  text-decoration: underline;
  color: #063B46!important;
}

a.external-link-white::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="rgb(255, 255, 255)" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>');
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 0.25rem;
    vertical-align: text-top;
}

#interview-outer-container a.phone-link::before,
#generic-dialog a.phone-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0,162,192)' class='w-6 h-6'%3e%3cpath fill-rule='evenodd' d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z' clip-rule='evenodd' /%3e%3c/svg%3e ");
  margin-right: 0.25rem;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  transform: rotate(0.75turn);
  vertical-align: text-top;
}

#interview-outer-container a.email-link::before,
#generic-dialog a.email-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0,162,192)' class='w-6 h-6'%3e%3cpath d='M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z' /%3e%3c/svg%3e ");
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='rgb(0,162,192)' version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.9651 7.8302a0.86373 0.81175 42.257 0 0 0.12726 1.4698l8.5081 4.1737 6.1982-6.822a0.86373 0.81176 42.257 0 1 1.2786 1.1616l-6.1982 6.822 4.9679 8.0701a0.86373 0.81175 42.257 0 0 1.4752-0.013834 69.696 65.502 42.257 0 0 5.7654-21.735 0.86373 0.81175 42.257 0 0-1.0382-0.94326 69.694 65.5 42.257 0 0-21.084 7.8168z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
}

#interview-outer-container a.fax-link::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='rgb(0,162,192)' %3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M128 64v96h64V64H386.7L416 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L432 18.7C420 6.7 403.7 0 386.7 0H192c-35.3 0-64 28.7-64 64zM0 160V480c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zm480 32H128V480c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM256 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32 96a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM224 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
  padding: 1px;
  position: relative;
  top: -4px;
  right: -2px;
}

/* #interview-outer-container a.form-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg fill='rgb(0,162,192)' version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m6.5028 5.4591h8.6471a4.0943 4.0943 0 0 1 4.0882 4.0943v11.373a3.6394 3.6394 0 0 0 3.6394-3.6394v-11.697c0-1.8258-1.3648-3.4101-3.2318-3.5666a59.409 59.409 0 0 0-0.81643-0.060656 3.6394 3.6394 0 0 0-3.2305-1.9628h-1.8197a3.6394 3.6394 0 0 0-3.2305 1.9628c-0.27295 0.018197-0.54591 0.03882-0.81643 0.060656-1.8221 0.15407-3.1663 1.6668-3.2293 3.4356zm7.2763-3.6394a1.8197 1.8197 0 0 0-1.8197 1.8197h5.4591a1.8197 1.8197 0 0 0-1.8197-1.8197z' clip-rule='evenodd' fill-rule='evenodd'/%3e%3cpath d='m2.3907 9.0548c0-1.1797 0.95649-2.135 2.135-2.135h11.102c1.1797 0 2.135 0.95649 2.135 2.135v12.81c0 1.1785-0.95649 2.135-2.135 2.135h-11.102a2.135 2.135 0 0 1-2.135-2.135zm3.416 2.989a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.854zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401zm-2.562 3.416a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.854zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401zm-2.562 3.416a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.85401zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401z' clip-rule='evenodd' fill-rule='evenodd'/%3e%3c/svg%3e");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
  margin-top: 1px;
  margin-bottom: 2px;
}

#interview-outer-container a.web-link::before,
#generic-dialog a.web-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0,162,192)'%3e%3cpath stroke='rgb(0,162,192)' stroke-width='1' d='M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z' /%3e%3c/svg%3e ");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
}

#interview-outer-container a.external-link {
  text-decoration: underline;
  color: #00a2c0 ;
}

#interview-outer-container a.external-link::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="rgb(0,162,192)" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>');
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 0.25rem;
    vertical-align: text-top;
} */

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -3px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -3px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -3px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -3px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -3px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -3px;
  }
}

.extra-wide-button {
  width: calc(100vw - 2rem);
}

.extra-thin-button {
  min-width: 120px;
}

/* .back-link {
  width: calc(100vw - 8rem);
} */

 /* Tailwindcss default responsive breakpoints*/
 /* sm	- starts at width 640px	 */
@media (min-width: 640px) {

  .lth-vis-on-sm {
    display: block;
  }

  .lth-vis-on-xs-only {
    display: none;
  }

  .extra-wide-button {
    width:auto;
    margin-right: 1rem;
  }

  #interview-outer-container {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }

}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  #interview-outer-container {
    width: calc(100vw - 8rem);
    /* margin-left: 4rem;
    margin-right: 4rem; */
    margin-left: auto;
    margin-right: auto;
  }

  #interview-outer-container > h1,
  .modal-header {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
  }

  .aspect-da-image img {
    position: relative;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: 21/9;
  }

  .secondary {
    padding: 14px 22px!important;
  }

  .g-map {
    width: 100%;
    height: 33vh;
  }

  .back-link {
    width: calc(100vw - 8rem);
    margin: 0 4rem;
    max-width: 768px;
  }

}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {

  #interview-outer-container > h1,
  .modal-header {
    line-height: 2.15rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .secondary {
    padding: 14px 16px!important;
  }

  .secondary-wide {
    padding: 14px 32px!important;
  }

  #interview-outer-container {
    margin: 2rem auto 2rem auto;
    /* max-width: 1024px; */
  }

  .g-map {
      width: 100%;
      height: 30vh;
  }

  .back-link {
    max-width: 768px!important;
    /* margin: 2rem 8rem!important; */
    padding: 0 4rem!!important;
    color: white!important;
  }

  .aspect-da-image {
    /* aspect-ratio: 21 / 9; */
  }

  .da-image {
      margin: 1.5rem auto 1.5rem auto;
      width: calc(100% - 10rem)!important;
  }

  .aspect-da-image img {
    position: relative;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: 21/9;
  }

  .accordion-item-head > div:first-child {
    line-height: 1.5rem;
    margin: 0.5rem 0.65rem 0.5rem 0;
  }

  .accordion-item-head > div > svg {
    width: 16px;
    height: 16px;
  }

}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) {
    #interview-outer-container {
      margin: 2rem auto 2rem auto;
      max-width: 986px;
    }
 }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
