#RFS-StepperContainer {
  padding: 8px 0;
  margin: auto -24px;
}

#RFS-LabelContainer {
  display: none;
}

#RFS-StepButton {
  cursor: initial!important;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	   */
@media (min-width: 640px) { }

/* md	- starts at width 768px	   */
@media (min-width: 768px) { }

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
  /* #RFS-StepperContainer {
      padding-left: 0;
      padding-right: 0;
  } */

  #RFS-LabelContainer {
    display: block;
  }

  #RFS-StepperContainer {
    padding: 8px 0 16px 0;
    margin: auto -8px auto -32px;
  }
}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px */
@media (min-width: 1536px) { }
