#video-section-wrapper {
    width: 0;
    z-index: -100;
    opacity: 0;
}

.da-video {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
  padding: 0;
  margin: 1rem 0;
}

.da-video iframe {
  width: 100%;
  height: calc(100% + 1px);
  top: 0;
  left: 0;
  right: 0;
  border: none;
}


/* sm	- starts at width 640px	 */
@media (min-width: 640px) {

    #text-section.video-orig #interview #interview-outer-container > .interview-body > div {
      margin: 1.5rem 3rem 0 3rem;
    }
}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
      #text-section.video-orig #interview #interview-outer-container > .interview-body > div {
        margin: 0;
      }
  }

/* lg	- starts at width 1024px	 */

/* Large screen but not enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
  .da-video {
    display: none;
  }

  #video-section-wrapper {
    margin: auto 4rem;
    overflow: visible;
    width: calc(100% - 8rem);
    z-index: 0;
    opacity: 100;
  }

  #video-section-wrapper {
    display: block;
    margin: auto 4rem;
    overflow: visible;
    width: calc(100% - 8rem);
  }

  #video-section-wrapper > div.heroSection {
    background-color: transparent;
    width: 100%!important;
    overflow: hidden;
    background-image: none;
    display: flex;
    aspect-ratio: 16/9;
  }

  #video-section-wrapper > div.heroSection > iframe {
    width: 100%;
    height: calc(100% + 1px);
    top: 0;
    left: 0;
    right: 0;
    border: none;
    background-color: #efefef;
  }

  .video-sub {
    margin-left: 0;
  }

  .interview-with-video {
    background-image: none!important;
    border-left: 1px solid #efefef!important;
    height: calc(100vh - 100px);
  }

  .interview-with-video > div > #interview-outer-container {
    border: none;
  }
}

/* Large screen and enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (min-height: 769px) and (orientation:landscape) {

  #text-section.video-orig #interview #interview-outer-container > .interview-body > div {
    margin: 1.5rem 6rem 0 6rem;
  }
}
